<script lang="ts">
  import { type DeskContent } from 'common/src/types'
  import SwitchByType from '../atoms/switch-by-type.svelte'
  import { state } from '../../store/state'

  export let content: DeskContent

  const { updateContent } = state.editorShell

  let Meta: any = undefined

  const { currentContentIsInEdit } = state
  $: isInEdit = $currentContentIsInEdit
</script>

<div class="meta-pane w-full flex flex-col gap-4 p-4 overflow-y-auto overflow-x-hidden">
  <SwitchByType {content} bind:Meta />
  <svelte:component this={Meta} {content} {isInEdit} {updateContent} />
  <div class="pt-8"></div>
</div>

<style lang="postcss">
  .meta-pane :global(.bx--btn) {
    width: fit-content;
    padding: 0 1rem 0 1rem;
    border: unset;
    box-shadow: unset;
  }
</style>
