<script lang="ts">
  import { getContext } from 'svelte'
  import { FilterManager } from '../../../../../content/filter/filter-manager'
  import DateRangeFilter from '../../../../../content/filter/date-range-filter.svelte'

  import { state } from '../../../../../../store/state'
  import FakeLink from '../../../../../../style/fake-link.svelte'
  import { SEARCH_PANE_CONTEXT } from '../../search-pane'

  import FtsSearchBar from './fts-search-bar.svelte'
  import KindFilter from './kind-filter.svelte'
  import LookupFilter from './lookup-filter.svelte'

  export let filterManager: FilterManager

  const { settings } = state

  const { activeFilterManager } = getContext(SEARCH_PANE_CONTEXT) as any
  let filtersCollapsed = true

  $: {
    filterManager = $activeFilterManager
  }
  $: data = settings.data
  $: multiplier = $data.columnMultiplier || 1
</script>

<div
  class="filters-container flex flex-row flex-wrap min-w-full justify-between pr-2"
  class:collapsed={filtersCollapsed}
  class:col2={multiplier === 1}
  class:col3={multiplier === 1.5}
  class:col4={multiplier === 2}
>
  <div class="fts"><FtsSearchBar /></div>
  <div class="search-filter kind"><KindFilter /></div>
  <div class="search-filter date">
    {#each filterManager.dropDownFilters() as filter}
      {#if filter.field === 'date'}
        <DateRangeFilter {filter} floatingTitleText="Date" />
      {/if}
    {/each}
  </div>
  <div class="search-filter partition">
    <LookupFilter floatingTitleText={'Partition'} type="partitions" />
  </div>
  <div class="search-filter source">
    <LookupFilter floatingTitleText={'Source'} type="sources" filterable={true} />
  </div>
  <div class="expand-filters" class:hidden={!filtersCollapsed}>
    <FakeLink>
      <div role="button" tabindex="-1" on:keypress on:click={() => (filtersCollapsed = false)}>
        show all filters
      </div>
    </FakeLink>
  </div>
  <div class="collapse-filters" class:hidden={filtersCollapsed}>
    <FakeLink>
      <div role="button" tabindex="-1" on:keypress on:click={() => (filtersCollapsed = true)}>
        hide filters
      </div>
    </FakeLink>
  </div>
  <hr class="w-full" />
</div>

<style lang="postcss">
  :global(#mytype-body) .search-filter :global(div.label) {
    display: none;
  }

  .search-filter :global(.range-picker) {
    position: absolute;
    margin-top: 2.6rem;
  }

  .col2 .search-filter :global(.range-picker) {
    width: 75%;
    left: 4.8rem;
  }
  .search-filter :global(.style-slot .bx--multi-select__wrapper),
  .search-filter :global(.style-slot .filter),
  .search-filter :global(.style-slot .single-list) {
    background-color: unset;
  }

  .search-filter :global(.filter-control .bx--list-box__label) {
    font-weight: unset;
  }
  .search-filter :global(.filter-control .search) {
    left: 0;
    top: 1px;
    width: 10.4rem;
    border-left: 1px solid var(--selected);
    border-right: 1px solid var(--selected);
    position: static;
  }

  .search-filter :global(.filter-control.custom-range .bx--list-box__field) {
    min-width: 10rem;
    max-width: 10rem;
  }
  .search-filter :global(.filter .bx--list-box__field) {
    padding: 0 3rem 0 1rem;
  }
  .search-filter :global(.filter .bx--dropdown div.bx--list-box__menu) {
    left: 0;
    width: 10.3rem;
  }
  .filters-container {
    row-gap: 2rem;
  }
  .search-filter {
    width: 10.5rem;
    border: 0.1rem solid #ccc;
  }
  .search-filter :global(.style-slot) {
    @apply w-full h-full;
  }

  .fts {
    width: 65%;
    transition: width 0.5s;
  }
  .col2 .fts,
  .col4 .fts {
    width: 100%;
  }

  .fts :global(.fts-search-input) {
    width: 100%;
  }

  .col3 .fts :global(.archive-search-toggle) {
    left: 12rem;
    position: relative;
  }

  .expand-filters,
  .collapse-filters {
    display: none;
  }

  @media (max-height: 900px) {
    .filters-container {
      row-gap: 1rem;
      padding-top: 0.5rem;
    }
    .expand-filters,
    .collapse-filters {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      flex-grow: 1;
    }
    .col4 .expand-filters,
    .col4 .collapse-filters {
      display: none;
    }
    .filters-container .fts {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      width: calc(100% - 3.5rem);
    }
    .filters-container.collapsed:not(.col4) .search-filter:not(.kind) {
      display: none;
    }
  }
</style>
